<template>
  <div>
    <span @click="toDownload">跳转 应用商城</span>
    <div id="qrcode"></div>
    <!-- 创建一个div，并设置id为qrcode -->
  </div>
</template>

<script>
// import { visitUrl } from "@/api/public.js";
import QRCode from "qrcodejs2"; // 引入qrcode
export default {
  data() {
    return {
      active: 2,
    };
  },
  methods: {
    // 新的方法
    toDownload() {
      var u = navigator.userAgent;
      var ua = u.toLowerCase();
      if (/iphone|ipad|ipod/.test(ua)) {
        // iOS 系统 ->  跳AppStore下载地址
        alert("iOS");
        window.location.href =
          "https://itunes.apple.com/cn/app/jie-zou-da-shi/id493901993?mt=8";
      } else if (/android/.test(ua)) {
        // 安卓系统 -> 跳安卓端下载地址
        alert("android");
        //1.1 如果是安卓先判断是否是外网
        window.location.href = "mimarket://details?id=com.xx.xx";
      } else if (this.IsPC()) {
        console.log("PC端");
        // PC端
      }
    },
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    },
    qrcode() {
      let qrcode = new QRCode("qrcode", {
        width: 132,
        height: 132,
        text: "http://192.168.40.29:8086/#/code/caB8", // 二维码地址
        colorDark: "#000",
        colorLight: "#fff",
      });
      console.log(qrcode._oDrawing._elCanvas.toDataURL("image/jpg"));
    },
    // 访问url
    // getVisitUrl(url) {
    //   visitUrl(url)
    //     .then((response) => {
    //       console.log("成功");
    //       console.log(response);
    //     })
    //     .catch(() => {
    //       console.log("失败咯");
    //     });
    // },
  },
  mounted() {
    // this.toDownload();
    this.qrcode();
  },
};
</script>

<style>
</style>